<template>
  <v-app id="inspire">
    <v-main v-bind:style= "'background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.5) ),' + bgcolor">
      <transition name="slide-fade" mode="out-in">
        <router-view/>
      </transition>

    </v-main>

    <!--<v-main>
      <HelloWorld/>
    </v-main>-->
    <Footer/>
  </v-app>
</template>

<script>
//import HelloWorld from './components/HelloWorld';
import Footer from './components/Footer';

export default {
  name: 'App',

  data: function() {
    return {
      bgcolor: '#'+(Math.random()*0xFFFFFF<<0).toString(16) + ''+ '66', // установка стартового цвета
      bgTimerId: null
    }
  },
  methods: {
    generateBg: function(){
      this.bgcolor = '#'+(Math.random()*0xFFFFFF<<0).toString(16) + ''+ '66'; // смена цвета
    }
  },
  mounted() {
    this.bgTimerId = setInterval(() => this.generateBg(), 5500); // запуск смены  цвета каждые 5 сек
  },
  components: {
      //HelloWorld,
      Footer,
  },
};
</script>

<style>
  .roboto-mono {
    font-family: Roboto Mono, Monospaced;
  }
  main {
    -webkit-transition: background 5s linear !important;
    transition: background 5s linear !important;
  }


  /* Анимации появления и исчезновения могут иметь */
  /* различные продолжительности и динамику.       */
  .slide-fade-enter-active {
    transition: all .5s ease;
  }
  .slide-fade-leave-active {
    transition: all .5s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(20px);
    opacity: 0;
  }
</style>
