<template>
    <v-footer padless>
        <v-col
                class="text-center ml-auto mr-auto"
                cols="12"
                sm="10"
                md="6"
        >
            <v-btn
                    to="/"
                    class="mx-4 "
                    text
            >
                home
            </v-btn>

            <v-btn
                    to="/site-info"
                    class="mx-4 "
                    text
            >
                site info
            </v-btn>

            {{ new Date().getFullYear() }}  &copy; E. Vetrov

        </v-col>


    </v-footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>