import Vue from 'vue'
import VueRouter from 'vue-router'
import VueYandexMetrika from 'vue-yandex-metrika'
import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
      path: '/site-info',
      name: 'SiteInfo',
      component: () => import(/* webpackChunkName: "about" */ '../views/SiteInfo.vue')
  },
  {
      path: '/my-projects',
      name: 'MyProjects',
      component: () => import(/* webpackChunkName: "about" */ '../views/MyProjects.vue')
  },
  {
    path: '/my-projects/:project',
    name: 'Project',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectPage.vue')
  },
  {
    path: '/relax',
    name: 'Relax',
    component: () => import(/* webpackChunkName: "about" */ '../views/Relax.vue')
  }

  //{ path: '*', component: NotFoundComponent }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

Vue.use(VueYandexMetrika, {
  id: 68775259,
  router: router,
  env: process.env.NODE_ENV
  // other options
});

export default router
