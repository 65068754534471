<template>
    <v-container
            class="fill-height"
            fluid
    >
        <v-row
                align="center"
                justify="center"
        >
            <v-col
                    cols="12"
                    sm="12"
                    md="12"
            >
                <v-card
                        class="mx-auto elevation-12"
                        max-width="600"
                >
                    <v-img
                            class="white--text align-end"
                            height="280px"
                            :src="require('../assets/me.jpg')"
                    >
                        <v-card-title class="roboto-mono" style="text-shadow: 0 0 3px #00000078">Evgeny Vetrov</v-card-title>
                    </v-img>


                    <v-card-text class="text--primary roboto-mono">
                        <div>Hello! I'm a fullstack web developer. Here you can see my skills, projects, experience, notes and some interesting things.</div>
                        <!--<div>Fusce fermentum odio nec arcu. Pellentesque libero tortor, tincidunt et, tincidunt eget, semper nec, quam. Phasellus blandit leo ut</div>-->
                        <!-- строка со ссылками -->
                        <v-tabs vertical class="pt-5">
                            <v-tab>
                                <v-icon left>mdi-monitor-cellphone-star</v-icon>
                                Projects
                            </v-tab>

                            <v-tab>
                                <v-icon left>mdi-code-braces</v-icon>
                                Skills
                            </v-tab>

                            <!--<v-tab>
                                <v-icon left>mdi-test-tube</v-icon>
                                Tests
                            </v-tab>-->
                            <!--<v-tab>
                                <v-icon left>mdi-toolbox-outline</v-icon>
                                Services
                            </v-tab>
                            <v-tab>
                                <v-icon left>mdi-script-text</v-icon>
                                Notes
                            </v-tab>-->
                            <v-tab>
                                <v-icon left>mdi-chat</v-icon>
                                Contacts
                            </v-tab>

                            <!-- проекты -->
                            <v-tab-item>
                                <v-card flat>
                                    <v-card-text>
                                        <p>
                                            There are several projects that I have created from scratch for self-realization, improving the world, improving my skills, trying out technologies or just for fun.
                                        </p>
                                        <p>
                                            There are also friendly projects that I helped or they helped me.
                                        </p>
                                        <v-card-actions>
                                            <!--<v-btn
                                                    class="pl-4 pr-4"
                                                    color="primary"
                                                    text
                                            >
                                                friendly
                                            </v-btn>-->

                                            <v-btn
                                                    to="/my-projects"
                                                    class="ml-auto pl-4 pr-4"
                                                    color="primary"
                                            >
                                                my projects
                                            </v-btn>
                                        </v-card-actions>

                                    </v-card-text>
                                </v-card>
                            </v-tab-item>

                            <!-- skills -->
                            <v-tab-item>
                                <v-card flat>
                                    <v-card-text>
                                        <p>
                                            I like to implement functions from idea to release to production. That is why I strive to have the necessary skills for a full development cycle.
                                        </p>
                                        <p>
                                            Briefly about my stack: Yii2, a bit Laravel (backend PHP frameworks), JS, JQ, Vue.js, MySQL, Nginx, and some other technologies which I know and experience I have.
                                        </p>
                                        <p>
                                            There is also an interesting non-technical experience. For more info chat me.
                                        </p>

                                        <!--<v-card-actions>
                                            <v-btn
                                                    class="ml-auto pl-4 pr-4"
                                                    color="primary"
                                            >
                                                more info
                                            </v-btn>
                                        </v-card-actions>-->
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>

                            <!-- контакты -->
                            <v-tab-item>
                                <v-card flat>
                                    <v-card-text>
                                        <p>
                                            @evetrov - telegram <br>
                                            vk.com/soft.soft - Vkontakte <br>
                                            email - for spam and registrations
                                        </p>

                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                        </v-tabs>
                        <!-- end строка со ссылками -->

                    </v-card-text>





                    <v-card-actions>
                        <!--<v-btn
                                color="primary"
                                text
                        >
                            click here
                        </v-btn>-->




                        <v-dialog
                                class="roboto-mono"
                                v-model="dialog"
                                persistent
                                max-width="310"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        class="ml-auto"
                                        color="red"
                                        text
                                        right
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    don't click
                                </v-btn>
                            </template>
                            <v-card
                                class="roboto-mono"
                            >
                                <v-card-title class="roboto-mono">
                                    Don't waste your time
                                </v-card-title>
                                <v-card-text>Of course we are always wasting our time. Let's relax for the next 5 minutes.</v-card-text>
                                <v-card-actions>
                                    <v-btn
                                            color="primary "
                                            left
                                            @click="dialog = false"
                                    >
                                        back to business
                                    </v-btn>
                                    <v-btn
                                            color="red darken-1"
                                            text
                                            to="/relax"
                                            class="ml-auto"
                                    >
                                        GO
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>


                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Home",
        data: () => ({
            dialog: false,
        })

    }
</script>

<style scoped>
    .v-tab {
        justify-content: left;
    }
</style>